body {
    background-color: #fff;
}

.loader {
    width: 376px;
    height: 200px;
    background-color: #fff;
    margin: 3em auto;
    text-align: center;
}

.loading-text {
    display: inline-block;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 32px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
